.App {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Logo-Container {
  flex-grow: 0.8;
  position: relative;
  margin: 10px;
  background-color: #00000099;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #83bae6;
}

.App-Section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding-top: 10vmin;
  padding-bottom: 10vmin;
}

.App-Article {
  max-width: 900px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 1vmin;
}

.even {
  background-color: #282c34;
  color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;

}
.House-logo {
  height: 20vmin;
  pointer-events: none;
  position: absolute;
  top: 25%;
  left: 25%;
}


.App-header {
  background: url("assets/images/bg_1.png") center center no-repeat;
  background-size: contain;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-videoLink {
  color: #61dafb;
  text-decoration: underline;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
